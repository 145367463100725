import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  /* Heading, */ Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  StatLabel,
} from "@chakra-ui/react";
import { AppTemplate } from "../../shared/ui/AppTemplate";
import { sendLeadToVakas } from "../../shared/api/auth";
import { useHistory } from "react-router-dom";
import { Routes } from "../../shared/enums/routes";

function isNumeric(value: string) {
  if (value === "") {
    return true;
  }

  return /^-?\d+$/.test(value);
}

const utm_source = "bp.missis-laser.ru";
const utm_campaign = "БП за 990 р";
const tag = "Акция Бикини+Подмышки 990 руб 01.2023";

export const Home = observer(() => {
  const [error, setError] = useState<boolean>();
  const [username, setUserName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const history = useHistory();

  const onSubmit = async () => {
    try {
      await sendLeadToVakas({ tag, phone, name: username, utm_source, utm_campaign }).then(({ data }) => {
        console.log("sendLeadToVakas", data);
      });
    } catch (error) {
      history.push(Routes.Success);
      console.log(JSON.stringify(error));
    }
  };

  return (
    <AppTemplate
      headerTitle='Тест драйв'
      headerBody={
        <StatLabel style={{ textAlign: "center", fontSize: 15 }}>
          Вы стали участником закрытой акции и были выбраны одной из победительниц для получения подарка:
          <br /> Лазерная эпиляция зон Бикини + Подмышки за 990 руб вместо 2300 руб!
          <br />
          <br />
          Акция действует только до конца Января! Так же можете поделиться ссылкой с подругами, которые ранее не
          пользовались услугами нашей клиники и они также получат этот подарок! Успейте оставить заявку прямо сейчас,
          чтобы получить подарок! Ждём Вас!
        </StatLabel>
      }
      //headerBody={`Акция для новых клиентов только в Январе!\nЛазерная эпиляция зон Бикини + Подмышки за 990 руб вместо 2300 руб! Пока есть возможность - оставляйте заявку и приходите на процедуру! Ждём Вас!`}
    >
      <Box
        p={0}
        overflowY='auto'
        flexGrow={1}
        style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Stack spacing='2rem'>
          <Stack spacing='1rem'>
            <InputGroup>
              <Input
                maxLength={30}
                /* isInvalid={error} */
                placeholder='Ваше имя'
                value={username}
                onChange={e => setUserName(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon children='+7' />
              <Input
                maxLength={10}
                type='tel'
                /* isInvalid={error} */
                placeholder='Номер телефона'
                value={phone}
                onChange={event => {
                  isNumeric(event.target.value) && setPhone(event?.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
          </Stack>
          <Stack spacing='1rem' style={{ marginTop: 0 }}>
            {error && (
              <Alert status='error'>
                <AlertIcon />
                К сожалению, данная акция только на первую процедуру! <br />
                Но у нас есть классные акции и для наших постоянных клиентов. <br />
                Пожалуйста, напишите нам в директ Instagram ваш телефон и наш менеджер вам все расскажет ;)
              </Alert>
            )}
          </Stack>
          <Button onClick={onSubmit} mt='24px' w='100%' alignSelf='center' colorScheme='teal' variant='solid'>
            Получить подарок
          </Button>
        </Stack>
      </Box>
    </AppTemplate>
  );
});
